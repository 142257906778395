import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'sw-ui';
import { observer } from 'mobx-react-lite';

import { rootService } from '../../../v2/core/business';
import lodashReplaces from '../../../bi/utils/lodashReplaces';

import SelectAnalytics from './selectAnalytics';

import styles from '../styles/form.module.scss';

const AnalyticsRows = observer(({
  analytics,
  tripAnalyticsValueIds,
  serviceAnalyticsValueIds,
  onSelect,
  validation,
}) => {
  const arrOfAnalyticsSlices = lodashReplaces.chunk(analytics, 3);
  const [selectedAnalyticsValueIds, setSelectedAnalyticsValueIds] = useState();
  const { loading, analyticsData } = rootService.services.analytics.customAnalytics;

  useEffect(() => {
    const calculateSelectedValueIds = () => {
      const ids = analytics.map((item) => (item.ApplyToTrip ? tripAnalyticsValueIds : serviceAnalyticsValueIds)).flat();
      setSelectedAnalyticsValueIds([...new Set(ids)]);
    };

    calculateSelectedValueIds();
  }, [analytics]);

  useEffect(() => {
    if (!selectedAnalyticsValueIds) return;

    rootService.services.analytics.getAnalyticByValueId(selectedAnalyticsValueIds);
  }, [selectedAnalyticsValueIds]);

  const rows = arrOfAnalyticsSlices.map((arr, i) => {
    const analyticsSlicesHtml = arr.map((item) => {
      const currentValidation = validation[item.Id] || '';
      const value = analyticsData.filter(({ UserAnalyticsId }) => UserAnalyticsId === item.Id)[0] || {};

      return (
        <SelectAnalytics
          key={ item.Id }
          analytics={ item }
          onSelect={ ({ id }) => onSelect({ analytics: item, analyticsValueId: id }) }
          validation={ currentValidation }
          value={ value }
        />
      );
    });

    return (
      <div key={ i } className={ styles.row }>
        { analyticsSlicesHtml }
      </div>
    );
  });

  if (loading) {
    return (
      <Loading size='small' />
    );
  }

  return (
    <div className={ styles.rows_analytics }>
      { rows }
    </div>
  );
});

AnalyticsRows.propTypes = {
  analytics: PropTypes.array,
  serviceAnalyticsValueIds: PropTypes.array,
  tripAnalyticsValueIds: PropTypes.array,
  onSelect: PropTypes.func,
  validation: PropTypes.object,
};

AnalyticsRows.defaultProps = {
  analytics: [],
  serviceAnalyticsValueIds: [],
  tripAnalyticsValueIds: [],
  onSelect: () => {},
  validation: {},
};

export default AnalyticsRows;
